body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-delete > div.actions > button.primary {
  background-color: #d82727;
}

.btn-delete > div.actions > button.primary:hover {
  background-color: #e04747;
}

.min-component-vertical-margin {
  margin-top: 0.1rem !important;
  margin-bottom: 0.1rem !important;
}

.min-component-horizontal-margin {
  margin-right: 0.2rem !important;
  margin-left: 0.2rem !important;
}

.highlighted-border-top-left {
  border-top: 2px solid #a0a0a0 !important;
  border-left: 2px solid #a0a0a0 !important;
}

.highlighted-border-top-right {
  border-top: 2px solid #a0a0a0 !important;
  border-right: 2px solid #a0a0a0 !important;
}

.highlighted-border-top {
  border-top: 2px solid #a0a0a0 !important;
}

.highlighted-border-expanded-row-cell {
  border-right: 2px solid #a0a0a0 !important;
  border-left: 2px solid #a0a0a0 !important;
  border-bottom: 2px solid #a0a0a0 !important;
}

.contract-plans-expanded-row-th th {
  position: sticky;
  top: 0;
  z-index: 1;
}

@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

.custom-input-number input[type='number']::-webkit-inner-spin-button,
.custom-input-number input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-input-number input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
